import React from "react"
import {graphql} from "gatsby"
import { Breadcrumb } from "gatsby-plugin-breadcrumb"
import Layout from "../components/layout"
import SEO from '../components/seo'
import Img from "gatsby-image";

const CompetitionTemplate = ({ pageContext, data }) => {
  const {
    breadcrumb: { crumbs },
  } = pageContext

  return (
    <Layout>
      <SEO
          title={data.competition.title}
          description={data.competition.body.value}
      />
      <div className="container content-page content-page-competition">
        <section className="section page-header">
          <Breadcrumb
            crumbs={crumbs}
            crumbSeparator=" > "
            crumbLabel={data.competition.title}
          />

          <h1 className="title is-1">{data.competition.title}</h1>

          {/*<div className="date">*/}
          {/*  <span>Valable du {data.competition.field_date.value} au {data.competition.field_date.end_value}</span>*/}
          {/*</div>*/}
        </section>

        <section className="section page-content">
          <div className="image">
            <div className="image-wrapper">
              <Img
                fluid={data.competition.relationships.image.localFile.childImageSharp.fluid}
                alt={data.competition.title}
              />
            </div>
          </div>

          {data.competition.body && (
            <div className="content" dangerouslySetInnerHTML={{ __html: data.competition.body.value }}/>
          )}

          {!data.competition.dateEnd_gte_now && (
            <div className="has-text-centered">
              <span className="button is-rounded is-light">Jeu terminé</span>
            </div>
          )}
        </section>
      </div>
    </Layout>
  )
}

export default CompetitionTemplate

export const query = graphql`
  query($internalId: Int!) {
    competition: nodeJeuConcours(status: {eq: true}, drupal_internal__nid: { eq: $internalId }) {
      id
      title
      body {
        value
      }
      field_date {
        end_value(formatString: "DD/MM/YY", locale: "fr")
        value(formatString: "DD/MM/YY", locale: "fr")
      }
      dateEnd_gte_now
      path {
        alias
      }
      relationships {
        image: field_image {
          localFile {
            childImageSharp {
              fluid(maxWidth: 890, maxHeight: 530, quality: 90) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      }
    }
  }
`
